import moment from 'moment';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { createTransition, MultiBackend } from 'react-dnd-multi-backend';
import { TouchBackend } from 'react-dnd-touch-backend';

import { generateRandomId } from '../Services/CalendarService';

const defaultTemplate = [
  {
    start: '7:30',
    end: '8:00',
    id: generateRandomId(),
    title: 'Quick Task',
    color: '#7F96FF'
  },
  {
    start: '8:00',
    end: '9:00',
    id: generateRandomId(),
    title: 'Meeting',
    color: '#7F96FF'
  },
  {
    start: '9:00',
    end: '12:00',
    id: generateRandomId(),
    title: 'Big Goal',
    color: '#FF1D7F'
  },
  {
    start: '12:00',
    end: '13:00',
    id: generateRandomId(),
    title: 'Break',
    color: '#00C57E'
  },
  {
    start: '13:00',
    end: '14:00',
    id: generateRandomId(),
    title: 'Quick Task',
    color: '#FA1DFF'
  },
  {
    start: '14:00',
    end: '15:00',
    id: generateRandomId(),
    title: 'Quick Task',
    color: '#FA1DFF'
  },
  {
    start: '15:00',
    end: '16:00',
    id: generateRandomId(),
    title: 'Quick Task',
    color: '#FA1DFF'
  },
  {
    start: '16:00',
    end: '16:30',
    id: generateRandomId(),
    title: 'Learning',
    color: '#3AAECA'
  },
  {
    start: '16:30',
    end: '17:00',
    id: generateRandomId(),
    title: 'Exercise',
    color: '#FC6949'
  }
];
const getHours = (dateString) => {
  const date = new Date(dateString);

  // Get hours and minutes
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  // Return in HH:mm format
  const time = `${hours}:${minutes}`;
  return time;
};

const addHoursToTime = (timeString, hoursToAdd) => {
  // Create a Date object with today's date and the time string
  const date = new Date(`1970-01-01T${timeString}:00`);

  // Add 1 hour
  date.setHours(date.getHours() + hoursToAdd);

  // Format the new time
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const newTime = `${hours}:${minutes}`;
  return newTime;
};
const timeToMinutes = (time) => {
  const [hours, minutes] = time.split(':').map(Number);
  return hours * 60 + minutes;
};

const minutesToTime = (minutes) => {
  const hours = Math.floor(minutes / 60)
    .toString()
    .padStart(2, '0');
  const mins = (minutes % 60).toString().padStart(2, '0');
  return `${hours}:${mins}`;
};

export const generateTemplate = (workStartTime, workEndTime) => {
  const workingStartHour = getHours(workStartTime);
  const workingEndHour = getHours(workEndTime);
  const workingStartTime = timeToMinutes(workingStartHour);
  const workingEndTime = timeToMinutes(workingEndHour);

  const eightAm = timeToMinutes('08:00');
  const sevenThirtyAm = timeToMinutes('07:30');
  const noonTime = timeToMinutes('12:00');
  const noonEndTime = timeToMinutes('13:00');

  let currentTime = workingStartTime;
  const template = [];

  // If work start time is before 8:00 AM, add a 30-minute quick task from 7:30 AM to 8:00 AM
  if (currentTime < eightAm) {
    if (currentTime < sevenThirtyAm) {
      currentTime = sevenThirtyAm; // Start at 7:30 if the workStart is earlier than 7:30
    }
    template.push({
      start: minutesToTime(currentTime),
      end: minutesToTime(eightAm),
      id: generateRandomId(),
      title: 'Quick Task',
      color: '#FA1DFF',
      type: 'todo'
    });
    currentTime = eightAm;
  }

  // Add 1-hour Meeting (Meeting should not start before 8:00 AM)
  template.push({
    start: minutesToTime(currentTime),
    end: minutesToTime(currentTime + 60),
    id: generateRandomId(),
    title: 'Meeting',
    color: '#7F96FF',
    type: 'meeting'
  });
  currentTime += 60;

  // Add Big Goal until noon (12 PM)
  if (currentTime < noonTime) {
    template.push({
      start: minutesToTime(currentTime),
      end: minutesToTime(noonTime),
      id: generateRandomId(),
      title: 'Daily Goal',
      color: '#FF1D7F',
      type: 'goal'
    });
    currentTime = noonTime;
  }

  // Add Break from 12 PM to 1 PM
  template.push({
    start: minutesToTime(noonTime),
    end: minutesToTime(noonEndTime),
    id: generateRandomId(),
    title: 'Break',
    color: '#00C57E',
    type: 'break',
  });
  currentTime = noonEndTime;

  // Check if there's time for a quick task after lunch
  if (currentTime + 60 <= workingEndTime - 180) {
    // Ensure we leave 3 hours at the end
    template.push({
      start: minutesToTime(currentTime),
      end: minutesToTime(currentTime + 60),
      id: generateRandomId(),
      title: 'Quick Task',
      color: '#FA1DFF',
      type: 'todo'
    });
    currentTime += 60;
  }

  // Remaining time should be allocated for Big Goal
  //Note: Add a break here
  const timeRemaining = workingEndTime - currentTime - 180; // Reserve 2 hours (120 minutes) for the tasks at the end
  const TimeToWork = timeRemaining > 240 ? (timeRemaining - 60) / 2 : timeRemaining;
  if (timeRemaining > 0) {
    template.push({
      start: minutesToTime(currentTime),
      end: minutesToTime(currentTime + timeRemaining),
      id: generateRandomId(),
      title: 'Daily Goal',
      color: '#FF1D7F',
      type: 'goal'
    });
    currentTime += timeRemaining;
  }

  // Add two Quick Tasks (1 hour each) before the end of the day
  for (let i = 0; i <= 2; i++) {
    if (currentTime + 60 <= workingEndTime - 60) {
      // Ensure we leave 60 minutes for Learning and Exercise
      template.push({
        start: minutesToTime(currentTime),
        end: minutesToTime(currentTime + 60),
        id: generateRandomId(),
        title: 'Quick Task',
        color: '#FA1DFF',
        type: 'todo'
      
      });
      currentTime += 60;
    }
  }

  // Add 30 mins Learning and 30 mins Exercise in the last hour
  if (currentTime <= workingEndTime - 60) {
    // Ensure there's time left for Learning and Exercise
    template.push({
      start: minutesToTime(currentTime),
      end: minutesToTime(currentTime + 30),
      id: generateRandomId(),
      title: 'Learning',
      color: '#3AAECA',
      type: 'learning'
    });
    currentTime += 30;

    template.push({
      start: minutesToTime(currentTime),
      end: minutesToTime(workingEndTime),
      id: generateRandomId(),
      title: 'Exercise',
      color: '#FC6949',
      type: 'exercise'
    });
  }

  return template;
};

export const localizer = momentLocalizer(moment);
export const DragAndDropCalendar = withDragAndDrop(Calendar);

export const timeFormats = {
  timeGutterFormat: (date) => moment(date).format('h a').replace('am', 'a').replace('pm', 'p')
};

export const HTML5toTouch = {
  backends: [
    {
      backend: HTML5Backend,
      transition: createTransition('pointerdown', (event) => !event.touches)
    },
    {
      backend: TouchBackend,
      options: { enableMouseEvents: true },
      preview: true
    }
  ]
};
