import { getFocusPercentage, getTimeDifferenceInMins } from '../utils/miscUtils';

export const goalEstimations = (dailyGoals) => {
  const goals = dailyGoals.filter((goal) => goal.taskType === 'goal' || goal.taskType === 'todo');
  const goalEstimations = goals.map((goal) => {
    return {
      name: goal?.name,
      set: goal?.estimatedTime && goal?.estimatedTime > 0 ? goal.estimatedTime * 60 : 0,
      spent: goal?.timeCompleted && goal?.timeCompleted > 0 ? goal.timeCompleted / 60 : 0
    };
  });
  const correctEstimations = goalEstimations.filter((goal) => goal.set > goal.spent);
  const goalsWithSpent = goalEstimations.filter((goal) => goal.spent > 0);
  const averageTimeSpentOnGoals =
    goalsWithSpent.reduce((acc, goal) => acc + goal.spent, 0) / goalsWithSpent.length || 0;

  const data = {
    goalEstimations,
    correctEstimations,
    averageTimeSpentOnGoals
  };
  return data;
};

export const getFocusScore = (dailyGoals) => {
  const goals = dailyGoals.filter((goal) => goal.taskType === 'goal' || goal.taskType === 'todo');
  const goalFocusScores = goals.map((goal) => {
    const blockedSites = goal?.blockedSites?.filter((tab) => tab?.Tag === 'Block').length;
    const tabsWithActiveTime = goal?.tabs
      ?.map((tab) => ({ ...tab, lastActive: getTimeDifferenceInMins(tab?.lastAccessed) }))
      ?.filter((tab) => tab?.lastActive <= 5);
    const activeSites = tabsWithActiveTime?.length;
    const percentage = getFocusPercentage(goal?.tabs?.length ?? 0, activeSites);
    return { name: goal.name, percentage, unusedTabs: goal?.tabs?.length - activeSites, blockedSites };
  });
  const goalsWithTabs = goalFocusScores.filter((goal) => goal?.tabs && goal?.tabs?.length > 0);
  const goalsWithBlockedSites = goalFocusScores.filter((goal) => goal?.blockedSites && goal?.blockedSites > 0);

  const totalUnusedTabs = goalsWithTabs.reduce((acc, goal) => acc + goal.unusedTabs, 0);
  const averageUnusedTabs = goalFocusScores.length > 0 ? totalUnusedTabs / goalFocusScores.length : 0;

  const totalBlockedSites = goalsWithBlockedSites.reduce((acc, goal) => acc + goal.blockedSites, 0);
  const averageBlockedSites = goalFocusScores.length > 0 ? totalBlockedSites / goalFocusScores.length : 0;

  const averageFocusScore =
    goalFocusScores.length > 0
      ? goalFocusScores.reduce((acc, goal) => acc + goal.percentage, 0) / goalFocusScores.length
      : 0;
  const burnoutRiskPercentage =
    goalFocusScores.length > 0
      ? goalFocusScores.filter((goal) => goal.percentage < 50).length / goalFocusScores.length
      : 0;

  return { goalFocusScores, averageUnusedTabs, averageBlockedSites, averageFocusScore, burnoutRiskPercentage };
};

export const getGoalCompletion = (dailyGoals) => {
  const goals = dailyGoals.filter((goal) => goal.taskType === 'goal' || goal.taskType === 'todo');
  const completedGoalsCount = goals.filter(
    (goal) => goal?.taskStatus === 'done' || goal?.taskStatus === 'review'
  )?.length;
  const notCompletedGoalsCount = goals.length - completedGoalsCount;
  const percentageCompletedGoals = goals.length > 0 ? (completedGoalsCount / goals.length) * 100 : 0;
  const percentageNotCompletedGoals = 100 - percentageCompletedGoals;
  const completedGoals = goals.filter(
    (goal) => goal.taskType === 'goal' && (goal?.taskStatus === 'done' || goal?.taskStatus === 'review')
  )?.length;
  const completedQuickTasks = goals.filter(
    (goal) => goal.taskType === 'todo' && (goal?.taskStatus === 'done' || goal?.taskStatus === 'review')
  )?.length;

  return {
    completedGoals,
    completedQuickTasks,
    percentageCompletedGoals: Math.floor(percentageCompletedGoals),
    percentageNotCompletedGoals: Math.floor(percentageNotCompletedGoals),
    completedGoalsCount,
    notCompletedGoalsCount
  };
};
