export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

/**
 * Sleep time for execution to complete.
 * @param {number} ms - number of milliseconds to wait
 * @return {Promise<void>}
 */
export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const getCurrentDate = () => {
  return new Date().toLocaleDateString('en-us');
};

export const limitText = (text) => {
  if (text.length > 15) {
    return `${text.length.slice(0, 15)}...`;
  }
  return text.length ?? '';
};

export const DEFAULT_REFERRAL_CODE = 'GETMOREDONE24';

export function getFormattedDate() {
  const today = new Date();
  const month = String(today.getMonth() + 1);
  const day = String(today.getDate()).padStart(2, '0');
  return month + '/' + day;
}

export function getIndex(index) {
  switch (index) {
    case 0:
      return 'first';
    case 1:
      return '2nd';
    case 2:
      return '3rd';
  }
}

export const getEmailDomain = (email) => {
  let defaultValue = '';
  if (!email) {
    return '';
  }
  const emailDomains = ['gmail', 'yahoo', 'outlook', 'Zoho', 'mail', 'Mozilla', 'icloud'];
  const brandName = email.split('@')[1].split('.')[0];
  defaultValue = brandName;
  for (const domain of emailDomains) {
    if (domain === brandName) {
      defaultValue = '';
      break;
    }
  }
  return defaultValue;
};

export const getTimeDifferenceInMins = (timestamp) => {
  // Get the current time in milliseconds
  const currentTime = Date.now();

  // Calculate the difference in milliseconds
  const timeDifferenceMs = currentTime - timestamp;

  // Convert the difference to minutes
  const timeDifferenceMinutes = timeDifferenceMs / 1000 / 60;
  return timeDifferenceMinutes;
};

export const getFocusPercentage = (tabs, activeTabs, improvedFocus = 0) => {
  if (tabs && tabs > 0) {
    const percentageOfEachTab = 90 / tabs;

    const unusedTabs = tabs - activeTabs;
    const percentageOfUnusedTabs = percentageOfEachTab * unusedTabs;
    const percentage = 100 - percentageOfUnusedTabs;
    return Math.floor(percentage);
  } else {
    return 50 + improvedFocus;
  }
};

export function formatTimeInSecs(seconds) {
  if (!isFinite(seconds)) {
    return {
      minutes: '00',
      seconds: '00'
    };
  }
  let minutes = Math.floor(seconds / 60);
  minutes = `${minutes}`;
  const remainingSeconds = seconds % 60;

  return {
    minutes: String(minutes).padStart(2, '0'),
    seconds: String(remainingSeconds).padStart(2, '0')
  };
}
