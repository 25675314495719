import {
  flowStates,
  MUSIC_STATIONS,
  siteBlockingTypes,
  TASK_DIFFICULTY,
  TASK_TABS,
  timerOptions
} from '../../utils/mixlFlow';
import { defaultBlockTimeInMinutes, IMixlData, IUserProfile, mixlActionTypes, TaskTypesList } from './types';

const breakTimeDefaultValue = 10; // minutes

const DEFAULT_siteBlocking = {
  type: siteBlockingTypes.warn
};

const defaultSites = [];
const INITIAL_MIXL_STATE: IMixlData = {
  selectedUserProfile: undefined,
  // new flow redux data
  generatingTaskData: [],
  fetchingMixlPlaylist: false,
  currentMusic: null,
  showRestartSessionModal: false,
  showClickupListModal: false,
  showAppUpdateModal: false,
  showWaveInfoModal: '',
  currentSessionId: undefined,
  userBlockedTime: defaultBlockTimeInMinutes * 60,
  blockTimeGoal: '', // task name, goal name
  showTimerCounting: false,
  currentPhase: flowStates.calm.phase,
  fullProgressValue: 0,
  fullCurrentMusicData: {
    hasMoreSongs: false,
    calm_phase: null,
    focus_phase: null,
    timestamp: null
  },
  clickupTasks: [],
  loadingclickupTasks: false,
  selectedTaskClickup: null,
  currentMixlScreen: null,
  milestones: [],
  musicStation: MUSIC_STATIONS[0].name,
  showMusicStationsModal: false,
  splitSessions: [],
  sessionStarted: false,
  musicPlaylists: null,
  playerVolume: 1,
  mainVolume: 1,
  showUpdateVolumeModal: false,
  breakTime: breakTimeDefaultValue * 60, // in seconds
  sessionLength: defaultBlockTimeInMinutes * 60,
  breakType: null,
  blockedSites: defaultSites,
  teamList: [],
  leaders: [],
  referralsList: [],
  mixlCalendarEvents: [],
  loadingTeam: false,
  showBinauralBeatsModal: false,
  stations: MUSIC_STATIONS,
  aiSessionsData: null,
  shouldClearTabs: false,
  publishToSlack: false,
  sessionQuoteWarmup: null,
  sessionQuoteBreak: null,
  skippedWarmup: false,
  endSessionLoading: false,
  siteBlocking: DEFAULT_siteBlocking,
  currentTaskRating: 50,
  showTaskDetailsModal: null,
  selectedOptionTimer: timerOptions[1],
  selectedProgressTab: TaskTypesList[1].name,
  sessionData: null,
  showSiteBlockerModal: false,
  showAddBlockSiteModal: false,
  generatingblockedSites: false,
  showTrackedSitesModal: false,
  blockAllSites: false,
  isScheduling: true,
  showWorkHoursModal: false,
  showFocusDetailsModal: false,
  showEndDetailsModal: false,
  selectedUser: null,
  isFetchingTeamList: false,
  showIntegrationsModal: false,
  showSlackMessageModal: false,
  creatingReadingList: false,
  playedEndSessionAudio: false,
  showFocusStatsModal: false,
  showTemplateModal: false,
  showGoalsModal: false,
  dailyGoals: []
};
export const mixlReducer = (state: IMixlData = INITIAL_MIXL_STATE, action): IMixlData => {
  switch (action.type) {
    case mixlActionTypes.PLAYED_END_SESSION_AUDIO: {
      return { ...state, playedEndSessionAudio: true };
    }
    case mixlActionTypes.UPDATE_MIXL_CALENDAR: {
      const newEvents = action.events;
      return { ...state, mixlCalendarEvents: [...newEvents] };
    }
    case mixlActionTypes.ADD_TASK_DATA_QUEUE: {
      const currentData = state.generatingTaskData;
      return { ...state, generatingTaskData: [...currentData, action.taskId] };
    }
    case mixlActionTypes.REMOVE_TASK_DATA_QUEUE: {
      const currentData = state.generatingTaskData;
      const taskId = action.taskId;
      const newData = currentData.filter((item) => item !== taskId);
      return { ...state, generatingTaskData: [...newData] };
    }
    case mixlActionTypes.ADD_SPLIT_SESSION_DATA: {
      return { ...state, splitSessions: [...action.sessions] };
    }
    case mixlActionTypes.CLEAR_SPLIT_SESSION_DATA: {
      return { ...state, splitSessions: [] };
    }
    case mixlActionTypes.ADD_BLOCKED_SITE: {
      const currentSites = state.blockedSites;
      const siteName = action.siteName;
      if (Array.isArray(siteName)) {
        return { ...state, blockedSites: [...siteName] };
      }
      return { ...state, blockedSites: [...currentSites, siteName] };
    }

    case mixlActionTypes.REMOVE_BLOCKED_SITE: {
      const currentSites = state.blockedSites;
      const siteName = action.siteName;
      if (!siteName) {
        return { ...state, blockedSites: [...defaultSites] };
      }
      const sitesArray = currentSites.filter((site) => site !== siteName);
      return { ...state, blockedSites: [...sitesArray] };
    }
    case mixlActionTypes.UPDATE_SESSION_ID: {
      return { ...state, currentSessionId: action.sessionId };
    }
    case mixlActionTypes.SHOW_RESTART_SESSION_MODAL: {
      return { ...state, showRestartSessionModal: action.status };
    }
    case mixlActionTypes.UPDATE_CURRENT_USER_INFO: {
      const user: IUserProfile = { ...state.selectedUserProfile };
      const avatarUrl = action.payload?.avatar;
      user.workingStartTime = action.payload?.workingStartTime;
      user.workingEndTime = action.payload?.workingEndTime;
      if (avatarUrl && avatarUrl !== user.avatar) {
        user.avatar = avatarUrl;
      }
      const userName = action.payload?.userName;
      if (userName && userName !== user.userName) {
        user.userName = userName;
      }
      return {
        ...state,
        selectedUserProfile: { ...user }
      };
    }
    case mixlActionTypes.FETCHING_PLAYLIST: {
      return {
        ...state,
        fetchingMixlPlaylist: action.payload
      };
    }

    case mixlActionTypes.SET_CURRENT_MUSIC: {
      return {
        ...state,
        currentMusic: action.payload
      };
    }

    case mixlActionTypes.SET_TIMER_VALUE: {
      return {
        ...state,
        userBlockedTime: action.payload
      };
    }
    case mixlActionTypes.SET_BLOCK_TIME_GOAL: {
      return {
        ...state,
        blockTimeGoal: action.payload
      };
    }

    case mixlActionTypes.SET_STOP_START_TIMER: {
      return {
        ...state,
        showTimerCounting: action.payload
      };
    }

    case mixlActionTypes.SET_CURRENT_PHASE: {
      return {
        ...state,
        currentPhase: action.payload
      };
    }

    case mixlActionTypes.SET_FULL_CURRENT_MUSIC: {
      return {
        ...state,
        fullCurrentMusicData: action.payload
      };
    }

    case mixlActionTypes.SET_FULL_PROGRESS_MUSIC: {
      return {
        ...state,
        fullProgressValue: action.payload
      };
    }

    case mixlActionTypes.SHOW_WAVE_INFO_MODAL: {
      return { ...state, showWaveInfoModal: action.payload };
    }

    case mixlActionTypes.SHOW_CLICKUP_LIST_MODAL: {
      return { ...state, showClickupListModal: action.payload };
    }
    case mixlActionTypes.LOADING_CLICKUP_LIST_TASKS: {
      return { ...state, loadingclickupTasks: action.payload };
    }
    case mixlActionTypes.SET_CLICKUP_LIST_TASKS: {
      return { ...state, clickupTasks: action.payload };
    }
    case mixlActionTypes.SET_SELECTED_TASK_CLICKUP: {
      return { ...state, selectedTaskClickup: action.payload };
    }
    case mixlActionTypes.SHOW_APP_UPDATE_MODAL: {
      return { ...state, showAppUpdateModal: action.payload };
    }
    case mixlActionTypes.SET_MIXL_SCREEN: {
      return { ...state, currentMixlScreen: action.payload };
    }
    case mixlActionTypes.SET_MILESTONES: {
      const newData = action?.payload ?? [];
      return { ...state, milestones: [...newData] };
    }

    case mixlActionTypes.UPDATE_MUSIC_STATION: {
      return { ...state, musicStation: action.payload };
    }
    case mixlActionTypes.SHOW_STATIONS_LIST_MODAL: {
      return { ...state, showMusicStationsModal: action.payload };
    }
    case mixlActionTypes.UPDATE_SESSION_STATUS: {
      return { ...state, sessionStarted: action.payload };
    }
    case mixlActionTypes.SET_MUSIC_LIST: {
      return { ...state, musicPlaylists: action.payload };
    }
    case mixlActionTypes.UPDATE_PLAYER_VOLUME: {
      return { ...state, playerVolume: parseFloat(action.payload) };
    }
    case mixlActionTypes.UPDATE_MAIN_VOLUME: {
      return { ...state, playerVolume: parseFloat(action.payload), mainVolume: parseFloat(action.payload) };
    }
    case mixlActionTypes.SHOW_UPDATE_VOLUME_MODAL: {
      return { ...state, showUpdateVolumeModal: action.payload };
    }
    case mixlActionTypes.SET_BREAK_TIME: {
      return { ...state, breakTime: action.payload };
    }
    case mixlActionTypes.SET_BREAK_TYPE: {
      return { ...state, breakType: action.payload };
    }
    case mixlActionTypes.SET_SESSION_FULL_TIME: {
      return { ...state, sessionLength: action.payload };
    }
    case mixlActionTypes.SET_TEAM_LIST: {
      return { ...state, teamList: action.payload };
    }
    case mixlActionTypes.SET_TEAM_LEADERS: {
      return { ...state, leaders: action.payload };
    }
    case mixlActionTypes.SET_REFERRALS_LIST: {
      return { ...state, referralsList: action.payload };
    }
    case mixlActionTypes.LOADING_TEAM_LIST: {
      return { ...state, loadingTeam: action.payload };
    }
    case mixlActionTypes.SHOW_BINAURAL_BEATS_MODAL: {
      return { ...state, showBinauralBeatsModal: action.payload };
    }
    case mixlActionTypes.SET_MUSIC_STATIONS: {
      return { ...state, stations: action.payload };
    }
    case mixlActionTypes.SET_AI_SESSIONS_DATA: {
      return { ...state, aiSessionsData: action.payload };
    }
    case mixlActionTypes.SET_CLEAR_TABS: {
      return { ...state, shouldClearTabs: action.payload };
    }
    case mixlActionTypes.PUBLISH_TO_SLACK: {
      return { ...state, publishToSlack: action.payload };
    }

    case mixlActionTypes.SET_SESSION_WARMUP_QUOTE: {
      return { ...state, sessionQuoteWarmup: action.payload };
    }
    case mixlActionTypes.SET_SESSION_BREAK_QUOTE: {
      return { ...state, sessionQuoteBreak: action.payload };
    }
    case mixlActionTypes.SET_SKIPPED_WARMUP: {
      return { ...state, skippedWarmup: action.payload };
    }
    case mixlActionTypes.SET_END_SESSION_LOADING: {
      return { ...state, endSessionLoading: action.payload };
    }
    case mixlActionTypes.SET_WEBSITES_BLOCKING_TYPE: {
      return { ...state, siteBlocking: { ...state.siteBlocking, type: action.payload } };
    }
    case mixlActionTypes.SET_RATING_CURRENT_SESSION: {
      return { ...state, currentTaskRating: action.payload };
    }
    case mixlActionTypes.SHOW_TASK_DETAILS_MODAL: {
      return { ...state, showTaskDetailsModal: action.payload };
    }
    case mixlActionTypes.SET_SELECTED_TIMER_OPTION: {
      return { ...state, selectedOptionTimer: action.payload };
    }
    case mixlActionTypes.SET_SELECTED_PROGRESS_TAB: {
      return { ...state, selectedProgressTab: action.payload };
    }

    case mixlActionTypes.SET_SESSION_DATA: {
      return { ...state, sessionData: action.payload };
    }
    case mixlActionTypes.SHOW_SITE_BLOCKER_MODAL: {
      return { ...state, showSiteBlockerModal: action.payload };
    }
    case mixlActionTypes.SET_BLOCKED_SITES: {
      return { ...state, blockedSites: action.payload };
    }
    case mixlActionTypes.SHOW_ADD_BLOCK_SITE_MODAL: {
      return { ...state, showAddBlockSiteModal: action.payload };
    }
    case mixlActionTypes.SET_GENERATING_BLOCKED_SITES: {
      return { ...state, generatingblockedSites: action.payload };
    }
    case mixlActionTypes.SET_SHOW_TRACKED_SITES_MODAL: {
      return {
        ...state,
        showTrackedSitesModal: action.payload
      };
    }
    case mixlActionTypes.SET_BLOCK_ALL_SITES: {
      return {
        ...state,
        blockAllSites: action.payload
      };
    }

    case mixlActionTypes.IS_SCHEDULING: {
      return {
        ...state,
        isScheduling: action.payload
      };
    }
    case mixlActionTypes.SET_SHOW_WORK_HOURS_MODAL: {
      return {
        ...state,
        showWorkHoursModal: action.payload
      };
    }
    case mixlActionTypes.SET_SHOW_FOCUS_DETAILS_MODAL: {
      return {
        ...state,
        showFocusDetailsModal: action.payload
      };
    }
    case mixlActionTypes.SET_SHOW_END_DETAILS_MODAL: {
      return {
        ...state,
        showEndDetailsModal: action.payload
      };
    }
    case mixlActionTypes.UPDATE_SELECTED_USER: {
      return { ...state, selectedUser: action.payload };
    }
    case mixlActionTypes.SET_SELECTED_USER: {
      return {
        ...state,
        selectedUser: action.payload ? state.teamList.find((user) => user.email === action.payload) : null
      };
    }
    case mixlActionTypes.SET_FETCHING_TEAM_LIST: {
      return {
        ...state,
        isFetchingTeamList: action.payload
      };
    }
    case mixlActionTypes.SET_SHOW_INTEGRATIONS_MODAL: {
      return {
        ...state,
        showIntegrationsModal: action.payload
      };
    }
    case mixlActionTypes.SET_SHOW_SLACK_MESSAGE_MODAL: {
      return {
        ...state,
        showSlackMessageModal: action.payload
      };
    }
    case mixlActionTypes.SET_CREATING_READING_LIST: {
      return {
        ...state,
        creatingReadingList: action.payload
      };
    }
    case mixlActionTypes.SET_SHOW_FOCUS_STATS_MODAL: {
      return {
        ...state,
        showFocusStatsModal: action.payload
      };
    }
    case mixlActionTypes.SET_SHOW_TEMPLATE_MODAL: {
      return {
        ...state,
        showTemplateModal: action.payload
      };
    }
    case mixlActionTypes.SET_SHOW_GOALS_MODAL: {
      return {
        ...state,
        showGoalsModal: action.payload
      };
    }
    case mixlActionTypes.UPDATE_DAILY_GOALS: {
      return {
        ...state,
        dailyGoals: action.payload
      };
    }
    default:
      return state;
  }
};
